import { getKy, type KyInstance } from "@yakit/core/api";


export function makeAuthService({ ky }: { ky: KyInstance }) {
  auth.ky = ky
  return auth
}

const auth = {
  ky: undefined,
  async login({username, password}: {username:string, password:string}) {

    if (username.length === 0 || password.length === 0) {
      throw 'User Name and Password Must Not Be Empty'
    }

    let ky = auth.ky
    try {
      const keyData = await ky.post('oauth/token',{
        headers: {
          'Authorization': `Basic ${btoa(`${username}:${password}`)}`
        }
      }).json();


      //const keyData = await ky.post('oauth/token', payload).json()
      localStorage.setItem('bearer', keyData['access_token'])
    } catch (error) {
      console.error('Fetch error:', error) // btnClick = function(event) {
      const resp = error.response
      if (resp.status === 401) {
        throw 'invalid authentication credentials'
      } else {
        throw error.message
      }
    }
  },

  async loginWithToken({token}: {token:string}) {
    try {
      localStorage.setItem('bearer', token)
      await auth.validate()
    } catch (error) {
      console.error('token error:', error) // btnClick = function(event) {
    }
  },

  async logout(){
    let ky = getKy()
    try {
      await ky.post('logout')
    } finally {
      localStorage.removeItem('bearer')
      document.location.reload()
    }
  },

  async validate(): Promise<boolean>{
    let ky = auth.ky
    try {
      await ky.get('validate')
      return true
    } catch(e) {
      return false
    }
  },

  // async getUserInfo(){
  //   let ky = auth.ky
  //   const curUser = await ky.get('currentUser').json()
  //   return curUser
  // }

}

export default auth
